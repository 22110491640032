import { Box } from "@mui/material";
import React, { FC, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { getRouteObjectByPathName } from ".";
import { useStorage } from "@/hooks/location-store";
import { getAuthorizationToken } from "@/core/utils/http-client";
import { toastError } from "@/core/utils/notification";
import { useLogout } from "@/hooks/login";

const AuthRouter: FC<{
    children: React.ReactElement
}> = ({ children }) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const logout = useLogout();
    const currentRoute = getRouteObjectByPathName(pathname);
    useEffect(() => {
        if ((currentRoute && currentRoute?.meta?.requiresAuth && !getAuthorizationToken())) {
            logout();
            // toastError("请先登录");
            navigate("/login");
        }
        if (currentRoute) {
            document.title = `${process.env?.REACT_APP_WEB_SIT_TITLE || "常青藤网上药店"}-${currentRoute?.meta?.title??'常青藤网上药店'}`;
        }
    }, [pathname, currentRoute]);
    return <React.Fragment>
        {children}
    </React.Fragment>
}

export default AuthRouter;