import React from "react";
import lazyLoad from "../utils/lazy-load";

export default [
    {
        path: "/app-update-version",
        element: lazyLoad(React.lazy(() => import("@/views/web-view-in-app/app-update-version"))),
        meta: {
            requiresAuth: false,
            title: "应用更新",
        },
    },
] as Array<RouteObject>;