
import { storageUtils } from "@/core/utils";
import { useStorage } from "./location-store";
import docCookies from "@/core/utils/cookie-util";
// import { useStorage } from "./location-store";

export const useLoginSuccess = () => {
  // const { addItem } = useStorage();
  return (model: UserLoginModel) => {
    if (process.env.NODE_ENV === "development") {
      storageUtils.addItem("AuthorizationToken", model.AuthorizationToken);
    } else {
      docCookies.setItem(
        "AuthorizationToken",
        model.AuthorizationToken,
        new Date(+new Date() + 1000 * 60 * 60 * 24)
      );
    }
    // addItem("AuthorizationToken", model.token);
    // addItem("Expire", model.expire);
  };
};

export const useLogout = () => {
  const { clean } = useStorage();
  return () => {
    //清空所有数据
    clean();
    docCookies.removeItem("AuthorizationToken");
    storageUtils.removeItem("AuthorizationToken")
    docCookies.removeItem("RefreshToken");
    storageUtils.removeItem("RefreshToken")
  };
};
